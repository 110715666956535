import axios from 'axios';
import { Token } from './tokens';
import { notification } from 'antd';

const { REACT_APP_API_PATH } = process.env;

const instance = axios.create({
	baseURL: REACT_APP_API_PATH,
	timeout: 10000,
});

function addAccessToken(request) {
	const accessToken = Token.getAccessToken();

	if (accessToken !== null) {
		request.headers.authorization = `Bearer ${accessToken}`;
	}
	return request;
}

instance.interceptors.request.use(addAccessToken);

instance.interceptors.response.use(
	request => request,
	async error => {
		if (error?.response?.status === 401) {
			let response = await axios.get(`${REACT_APP_API_PATH}/refresh`);
			if (!response.data) {
				notification.open({
					description: error.response.data.message || 'Ошибка доступа. Пользователь не авторизован',
					onClick: () => {
						console.log('Notification Clicked!');
					},
				});
				return { data: null };
				// return Promise.reject(error); // не было рефреш токена
			}

			Token.setAccessToken(response.data.accessToken);

			return axios(addAccessToken(error.config));
		}

		if (error?.response?.status === 500) {
			notification.open({
				description: error.response.data.message || 'Произошла непредвиденная ошибка. Перезагрузите страницу',
				onClick: () => {
					console.log('Notification Clicked!');
				},
			});
			// return Promise.reject(error);
			return { data: null };
		}
		if (error?.response?.status === 404) {
			notification.open({
				description: error.response.data.message || 'При отправке запросе получены неверные данные',
				onClick: () => {
					console.log('Notification Clicked!');
				},
			});
			return { data: null };
		}
		if (error?.response?.status === 400) {
			notification.open({
				description: error.response.data.message || 'Запрашиваемые данные не найдены',
				onClick: () => {
					console.log('Notification Clicked!');
				},
			});
			return { data: null };
		}

		return Promise.reject(error);
		// return { data: null };
	}
);

export { instance };
