import { setAuthAC } from '@/store/reducers/auth';
import logo from '@assets/img/logo.png';
import logoMobile from '@assets/img/logo-mobile.png';
import { Button, Layout, Row } from 'antd';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { HeaderNav } from './HeaderNav';

export const LayoutHeader = ({ isAuth }) => {
	const { Header } = Layout;

	const history = useHistory(),
		dispatch = useDispatch();

	const handleExit = () => {
		dispatch(setAuthAC({ user: null }));
		localStorage.removeItem('accessToken');
		history.push('/login');
	};

	return (
		<Header className="header" id="header">
			<Row align="middle">
				<picture>
					<source media="(max-width: 768px)" srcSet={logoMobile} />
					<img id="logo" height="30" src={logo} alt="logo" />
				</picture>
				<HeaderNav isAuth={isAuth} />
				{isAuth && (
					<Button style={{ marginLeft: 'auto' }} onClick={handleExit}>
						Выйти
					</Button>
				)}
			</Row>
		</Header>
	);
};
