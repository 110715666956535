import { combineReducers, createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { documentsReducer } from './reducers/documents';
import { contragentsReducer } from './reducers/contragents';
import { authReducer } from './reducers/auth';

import thunk from 'redux-thunk';

const reducers = combineReducers({
	documents: documentsReducer,
	contragents: contragentsReducer,
	auth: authReducer,
});
export const store = createStore(reducers, composeWithDevTools(applyMiddleware(thunk)));
