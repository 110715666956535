import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { App } from './App';
import { store } from '@store/store';
import { Provider } from 'react-redux';
import reportWebVitals from './reportWebVitals';
import { ConfigProvider } from 'antd';
import ruRU from 'antd/lib/locale/ru_RU';

ReactDOM.render(
	<React.StrictMode>
		<BrowserRouter>
			<ConfigProvider locale={ruRU}>
				<Provider store={store}>
					<App />
				</Provider>
			</ConfigProvider>
		</BrowserRouter>
	</React.StrictMode>,
	document.getElementById('root')
);

reportWebVitals();
