import { PlusSquareOutlined } from '@ant-design/icons';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { updateContragentsParamsAC } from '@/store/reducers/contragents';
import { Button, Card, Form, Select, Tag, Input, Divider } from 'antd';

const { Option } = Select;

export const ContragentsSidebar = ({ createTableRow, updateLimit, closeFiltersModal = () => {} }) => {
	const [form] = Form.useForm();
	const dispatch = useDispatch();

	const initialFilterState = {
		name: null,
		unp: null,
	};
	let [filter, setFilter] = useState(initialFilterState);
	let [nameType, setNameType] = useState('includes');
	let [filterName, setFilterName] = useState('');

	let [unpType, setUnpType] = useState('includes');
	let [filterUnp, setFilterUnp] = useState('');

	const updateName = (type, value) => {
		switch (type) {
			case 'startsWith':
				return `${value}%`;
			case 'endsWith':
				return `%${value}`;
			default:
				return `%${value}%`;
		}
	};

	const applyFilterOptions = () => {
		const options = Object.keys(filter).filter((key) => filter[key] !== null);
		const filteredOptions = options.reduce((obj, key) => {
			obj[key] = filter[key];
			return obj;
		}, {});
		options.length === 0 ? clearFilters() : dispatch(updateContragentsParamsAC('filter', filteredOptions));
		updateLimit();
		window.innerWidth <= 1300 && closeFiltersModal();
	};

	const clearFilters = () => {
		setFilter((prev) => ({ ...prev, ...initialFilterState }));
		setNameType('includes');
		setUnpType('includes');
		setFilterName('');
		setFilterUnp('');
		dispatch(updateContragentsParamsAC('filter', null));
	};

	return (
		<Card id="table-sidebar">
			<Form layout="vertical" form={form} onFinish={applyFilterOptions}>
				<Button type="primary" onClick={createTableRow}>
					<PlusSquareOutlined />
					<span>Добавить контрагента</span>
				</Button>

				<Divider />

				<h3>Фильтрация</h3>
				<Form.Item label="По названию контрагента:">
					<Select
						onChange={(value) => setNameType(value)}
						value={nameType}
						className="select-before"
						suffixIcon={<i className="ant-menu-submenu-arrow" />}
					>
						<Option value="startsWith">
							<Tag>Начинается с</Tag>
						</Option>
						<Option value="endsWith">
							<Tag>Заканчивается на</Tag>
						</Option>
						<Option value="includes">
							<Tag>Включает</Tag>
						</Option>
					</Select>

					<Input
						value={filterName}
						className="name-filter-input"
						onChange={(e) => {
							setFilterName(e.target.value);
							setFilter((prev) => ({
								...prev,
								name: e.target.value.trim() ? updateName(nameType, e.target.value) : null,
							}));
						}}
						placeholder="Введите имя контрагента"
					/>
				</Form.Item>

				<Form.Item label="По УНП">
					<Select
						onChange={(value) => setUnpType(value)}
						value={unpType}
						className="select-before"
						suffixIcon={<i className="ant-menu-submenu-arrow" />}
					>
						<Option value="startsWith">
							<Tag>Начинается с</Tag>
						</Option>
						<Option value="endsWith">
							<Tag>Заканчивается на</Tag>
						</Option>
						<Option value="includes">
							<Tag>Включает</Tag>
						</Option>
					</Select>

					<Input
						value={filterUnp}
						className="name-filter-input"
						onChange={(e) => {
							setFilterUnp(e.target.value);
							setFilter((prev) => ({
								...prev,
								unp: e.target.value.trim() ? updateName(unpType, e.target.value) : null,
							}));
						}}
						placeholder="Введите имя контрагента"
					/>
				</Form.Item>

				<Form.Item>
					<Button type="primary" htmlType="submit">
						<span>Применить фильтры</span>
					</Button>
				</Form.Item>

				<Form.Item>
					<Button type="primary" htmlType="reset" onClick={clearFilters}>
						<span>Очистить фильтры</span>
					</Button>
				</Form.Item>
			</Form>
			{/* )} */}
		</Card>
	);
};
