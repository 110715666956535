import { instance } from './http';
import { Token } from './tokens';

export const api = {
	async getAll(route, params = {}) {
		const response = await instance.get(`/${route}/`, { params });
		return response?.data;
	},

	async create(route, data = {}) {
		await instance.post(`/${route}/`, data);
	},

	async update(route, id, data = {}) {
		await instance.put(`/${route}/${id}`, data);
	},

	async delete(route, id) {
		await instance.delete(`/${route}/${id}`);
	},

	async deleteAll(route, id) {
		await instance.delete(`${route}/all/${id}`);
	},
};

export const auth = {
	async register(body = {}) {
		const response = await instance.post(`/registration`, body);
		return response?.data;
	},

	async login(body = {}, storeAuthData) {
		const response = await instance.post('/login', body);
		const accessToken = response?.data?.accessToken;

		if (accessToken) {
			Token.setAccessToken(accessToken);
			const user = Token.getJWTPayload(accessToken);
			storeAuthData({ accessToken, user });
		}
	},

	async checkUser() {
		const response = await instance.get(`/user/me`);
		return response?.data?.user;
	},
};
