import { Form, Input, Button } from 'antd';
import { auth } from '@services/api';
import { useDispatch } from 'react-redux';
import { setAuthAC } from '@store/reducers/auth';

export const Registration = () => {
	const dispatch = useDispatch();

	const onFinish = async user => {
		try {
			await auth.register(user);
			await auth.login(user, data => {
				dispatch(setAuthAC(data));
			});
		} catch (e) {
			console.log(e);
		}
	};

	const onFinishFailed = errorInfo => {
		console.log('Failed:', errorInfo);
	};

	return (
		<Form
			name="basic"
			labelCol={{ span: 8 }}
			wrapperCol={{ span: 16 }}
			initialValues={{ remember: true }}
			onFinish={onFinish}
			onFinishFailed={onFinishFailed}
		>
			<Form.Item
				label="Email"
				name="email"
				rules={[{ required: true, type: 'email', message: 'Введите email' }]}
				wrapperCol={{ span: 8 }}
			>
				<Input />
			</Form.Item>

			<Form.Item
				label="Пароль"
				name="password"
				rules={[
					{ required: true, message: 'Введите пароль' },
					{ min: 6, max: 16, message: 'Пароль должен включать от 6 до 16 символов' },
				]}
				wrapperCol={{ span: 8 }}
			>
				<Input.Password />
			</Form.Item>

			<Form.Item wrapperCol={{ span: 6 }} className="btn-center">
				<Button type="primary" htmlType="submit">
					Зарегистрироваться
				</Button>
			</Form.Item>
		</Form>
	);
};
