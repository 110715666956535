const SAVE_CONTRAGENTS = 'SAVE_CONTRAGENTS';
const CHANGE_CONTRAGENT = 'CHANGE_CONTRAGENT';
const UPDATE_CONTRAGENTS_PARAMS = 'UPDATE_CONTRAGENTS_PARAMS';

const initialState = {
	contragents: [],
	params: {
		filter: null,
		sort: null,
		pagination: {
			page: 1,
			limit: 20,
		},
	},
};

export const contragentsReducer = (state = initialState, action) => {
	switch (action.type) {
		case SAVE_CONTRAGENTS: {
			return { ...state, [action.dataKey]: action.data };
		}
		case CHANGE_CONTRAGENT: {
			return {
				...state,
				contragents: state.contragents.map((agent) => {
					if (agent.id === action.contragentId) {
						const changedContragent = { ...agent, [action.dataKey]: action.data };
						return changedContragent;
					}
					return agent;
				}),
			};
		}
		case UPDATE_CONTRAGENTS_PARAMS: {
			return { ...state, params: { ...state.params, [action.dataKey]: action.value } };
		}
		default: {
			return state;
		}
	}
};

export const saveContragentsAC = (dataKey, data) => ({ type: SAVE_CONTRAGENTS, dataKey, data });
export const changeContragentAC = (dataKey, data, contragentId) => ({
	type: CHANGE_CONTRAGENT,
	dataKey,
	data,
	contragentId,
});
export const updateContragentsParamsAC = (dataKey, value) => ({ type: UPDATE_CONTRAGENTS_PARAMS, dataKey, value });
