import React, { useState, useEffect } from 'react';
import { Button, Table, Spin } from 'antd';

import { useDispatch, useSelector } from 'react-redux';
import { saveDocumentsAC, changeDocumentAC, updateDocumentsParamsAC } from '@store/reducers/documents';
import { api } from '@services/api';
import { DocumentsSidebar } from './DocumentsSidebar';
import { DocumentsModal } from './DocumentsModal';
import { DocumentColumns } from './Columns';
import Modal from 'antd/lib/modal/Modal';
import { ControlTwoTone } from '@ant-design/icons';

export const DocumentsTable = () => {
	const documentsState = useSelector((store) => store.documents),
		{ documents, contragents, companies, statuses, types, params } = documentsState,
		// row id to update in modal
		[document_id, setDocument_id] = useState(null),
		// popup (modal) visibility
		[isVisible, setIsVisible] = useState(false),
		// actions to update or create a row
		[action, setAction] = useState(null),
		// table rows count
		[count, setCount] = useState(10),
		// table sort
		[sort, setSort] = useState({}),
		[openFilters, setOpenFilters] = useState(false),
		// table pagination
		{ page, limit } = params.pagination,
		[loading, setLoading] = useState(true),
		dispatch = useDispatch();

	// stores data
	const saveDocuments = (dataKey, data) => {
		dispatch(saveDocumentsAC(dataKey, data));
	};

	// refreshes query search params
	const updateDocumentsParams = (dataKey, value) => {
		dispatch(updateDocumentsParamsAC(dataKey, value));
	};

	// fetches list of documents and appropriate count
	const fetchDocuments = async (params) => {
		try {
			const { count, rows: documents } = await api.getAll('documents', params);
			saveDocuments('documents', documents);
			setCount(count);
		} catch (e) {
			console.log(e);
		}
	};

	const fetchCompanies = async () => {
		const companies = await api.getAll('companies');
		if (companies?.rows) {
			dispatch(saveDocumentsAC('companies', companies.rows));
		}
	};

	// fetched list of contragents
	const fetchContragents = async () => {
		const data = await api.getAll('contragents', { limit: null, offset: null });
		if (data?.rows) {
			saveDocuments('contragents', data.rows);
		}
	};

	// changes the doc
	const changeDocument = (dataKey, data, document_id) => {
		dispatch(changeDocumentAC(dataKey, data, document_id));
	};

	// delete the doc from DB
	const deleteDocument = async (document_id) => {
		try {
			await api.delete('documents', document_id);
			await fetchDocuments();
		} catch (e) {
			console.log(e);
		}
	};

	// handles sort among all the table
	const tableSortHandler = (pagination, filters, { field, order }) => {
		const transformedOrder = (() => {
			switch (order) {
				case 'ascend':
					return 'ASC';
				case 'descend':
					return 'DESC';
				default:
					return undefined;
			}
		})();

		setSort(() => ({ [field]: transformedOrder }));
	};

	const paginationHandler = async (paginationPage, paginationLimit) => {
		const paginationParams =
			page !== paginationPage ? { page: paginationPage, limit } : { page, limit: paginationLimit };
		updateDocumentsParams('pagination', paginationParams);
	};

	const closeModalHandler = () => {
		setDocument_id(null);
		setIsVisible(false);
		setAction(null);
	};

	// columns with appropriate props
	const columns =
		documents.length > 0 && contragents.length > 0 && companies.length > 0
			? DocumentColumns({
					apiUpdate: api.update,
					changeDocument,
					contragents,
					companies,
					types,
					statuses,
					setDocument_id,
					setAction,
					setIsVisible,
					deleteDocument,
			  })
			: [];

	const columnsScroll = (() => {
		const obj = {};
		if (window.innerWidth <= 1300) {
			obj.y = window.innerHeight - 240;
		}
		if (window.innerWidth <= 1000) {
			obj.x = 1000;
		}
		return Object.keys(obj).length > 0 ? obj : null;
	})();

	// fetches contragents on initial render
	useEffect(() => {
		(async () => {
			// await fetchDocuments();
			try {
				await fetchContragents();
				await fetchCompanies();
			} catch (e) {
				console.log(e);
			} finally {
				setTimeout(() => {
					setLoading(false);
				}, 200);
			}
		})();
	}, []);

	// refresh sort params
	useEffect(() => {
		const sortKeys = Object.keys(sort).filter((key) => !!sort[key]);
		const filteredSort = sortKeys.reduce((obj, key) => {
			obj[key] = sort[key];
			return obj;
		}, {});
		updateDocumentsParams('sort', sortKeys.length > 0 ? filteredSort : null);
	}, [sort]);

	// refresh documents when params are changed
	useEffect(() => {
		(async () => {
			const querySearch = { limit, page };

			!!params.sort && (querySearch.sort = params.sort);
			!!params.filter && (querySearch.filter = params.filter);

			await fetchDocuments(querySearch);
		})();
		console.log('changed page state');
	}, [params]);

	return (
		<div id="table-wrapper">
			{/* <DocumentsSidebar
				createTableRow={() => {
					setIsVisible(true);
					setAction('create');
				}}
				updateLimit={() => updateDocumentsParams('pagination', { page: 1, limit })}
				documentsState={documentsState}
			/> */}

			{window.innerWidth > 1300 ? (
				<DocumentsSidebar
					createTableRow={() => {
						setIsVisible(true);
						setAction('create');
					}}
					updateLimit={() => updateDocumentsParams('pagination', { page: 1, limit })}
					documentsState={documentsState}
				/>
			) : (
				<>
					<Button className="ant-filter-btn" onClick={() => setOpenFilters(true)}>
						<ControlTwoTone twoToneColor="#1890ff" />
						<span>&nbsp;Фильтр</span>
					</Button>

					<Modal
						className="filters-modal"
						onCancel={() => {
							setOpenFilters(false);
						}}
						visible={openFilters}
						footer={false}
					>
						<DocumentsSidebar
							createTableRow={() => {
								setIsVisible(true);
								setAction('create');
							}}
							updateLimit={() => updateDocumentsParams('pagination', { page: 1, limit })}
							documentsState={documentsState}
							closeFiltersModal={() => {
								setOpenFilters(false);
							}}
						/>
					</Modal>
				</>
			)}

			{documents?.length > 0 && contragents?.length > 0 && companies?.length && !loading ? (
				<Table
					border="1"
					columns={columns}
					onChange={tableSortHandler}
					dataSource={documents}
					rowKey="id"
					locale={{
						triggerDesc: 'Сортировка по возрастанию',
						triggerAsc: 'Нет сортировки',
						cancelSort: 'Сортировка по убыванию',
					}}
					pagination={{
						total: count,
						current: page,
						pageSize: limit,
						showSizeChanger: true,
						pageSizeOptions: ['10', '20', '50'],
						responsive: true,
						onChange: paginationHandler,
					}}
					scroll={columnsScroll}
					bordered
				/>
			) : (
				<Spin size="large" tip="Загрузка..." />
			)}
			{documents?.length === 0 && !loading && <h3 className="antd-table-empty">Таблица документов пуста</h3>}
			{!!action && (
				<DocumentsModal
					documentsState={documentsState}
					refreshDocuments={async () => {
						await fetchDocuments();
					}}
					action={action}
					document_id={document_id || null}
					closeModal={closeModalHandler}
					isVisible={isVisible}
				/>
			)}
		</div>
	);
};
