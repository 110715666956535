import { Button } from 'antd';
import { useHistory } from 'react-router-dom';

export const Error404 = () => {
	const history = useHistory();
	return (
		<div>
			<div>404 Error</div>
			<Button type="primary" onClick={() => history.push('/documents')}>
				Вернуться к документам
			</Button>
		</div>
	);
};
