import React, { useState, useEffect, useRef } from 'react';
import { Button, Table, Spin } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { saveContragentsAC, updateContragentsParamsAC } from '@store/reducers/contragents';
import { ContragentColumns } from './Columns';
import { ContragentsSidebar } from './ContragentsSidebar';
import { ContragentsModal } from './ContragentsModal';
import { api } from '@services/api';
import { tableSortHandler } from '@services/utils';
import { saveDocumentsAC } from '@store/reducers/documents';
import Modal from 'antd/lib/modal/Modal';
import { ControlTwoTone } from '@ant-design/icons';

export const ContragentsTable = () => {
	const firstRef = useRef(true);
	let [contragentId, setContragentId] = useState(null);
	let [isVisible, setIsVisible] = useState(false);
	let [action, setAction] = useState(null);
	let [openFilters, setOpenFilters] = useState(false);

	const dispatch = useDispatch();
	const { contragents, params } = useSelector((store) => store.contragents);
	const [count, setCount] = useState(10);
	// table sort
	const [sort, setSort] = useState({});
	// table pagination
	const { page, limit } = params.pagination;
	const [loading, setLoading] = useState(true);

	const documentsToFilter = useSelector((state) => state.documents.documents);

	const saveContragents = (dataKey, data) => {
		dispatch(saveContragentsAC(dataKey, data));
	};

	const updateContragentsParams = (dataKey, value) => {
		dispatch(updateContragentsParamsAC(dataKey, value));
	};

	const filterDocuments = (contragentId) => {
		dispatch(
			saveDocumentsAC(
				'documents',
				documentsToFilter.filter((el) => el.contragentId !== contragentId)
			)
		);
	};

	const fetchContragents = async (params = {}) => {
		const data = await api.getAll('contragents', params);
		if (data) {
			setCount(data.count);
			saveContragents('contragents', data.rows);
		}
		if (loading) {
			setTimeout(() => {
				setLoading(false);
			}, 200);
		}
	};

	const deleteContragent = async (contragentId) => {
		await api.deleteAll('documents', contragentId);
		await api.delete('contragents', contragentId);
		await fetchContragents();
	};

	const paginationHandler = async (paginationPage, paginationLimit) => {
		const paginationParams =
			page !== paginationPage ? { page: paginationPage, limit } : { page, limit: paginationLimit };
		updateContragentsParams('pagination', paginationParams);
	};

	const columns = ContragentColumns({ setContragentId, setAction, setIsVisible, deleteContragent, filterDocuments });

	const columnsScroll = (() => {
		const obj = {};
		if (window.innerWidth <= 1300) {
			obj.y = window.innerHeight - 240;
			obj.x = 1000;
		}
		// if (window.innerWidth <= 1000) {
		// 	obj.x = 1000;
		// }
		return Object.keys(obj).length > 0 ? obj : null;
	})();

	// refresh sort params
	useEffect(() => {
		if (firstRef.current) {
			firstRef.current = false;
		} else {
			const sortKeys = Object.keys(sort).filter((key) => !!sort[key]);
			const filteredSort = sortKeys.reduce((obj, key) => {
				obj[key] = sort[key];
				return obj;
			}, {});
			updateContragentsParams('sort', sortKeys.length > 0 ? filteredSort : null);
		}
	}, [sort]);

	useEffect(() => {
		(async () => {
			const querySearch = { limit, page };

			!!params.sort && (querySearch.sort = params.sort);
			!!params.filter && (querySearch.filter = params.filter);

			await fetchContragents(querySearch);
		})();
	}, [params]);

	return (
		<div id="table-wrapper">
			{window.innerWidth > 1300 ? (
				<ContragentsSidebar
					createTableRow={() => {
						setIsVisible(true);
						setAction(() => 'create');
					}}
					updateLimit={() => updateContragentsParams('pagination', { page: 1, limit })}
				/>
			) : (
				<>
					<Button className="ant-filter-btn" onClick={() => setOpenFilters(true)}>
						<ControlTwoTone twoToneColor="#1890ff" />
						<span>&nbsp;Фильтр</span>
					</Button>
					<Modal
						className="filters-modal"
						onCancel={() => {
							setOpenFilters(false);
						}}
						visible={openFilters}
						footer={false}
					>
						<ContragentsSidebar
							createTableRow={() => {
								setIsVisible(true);
								setAction(() => 'create');
							}}
							updateLimit={() => updateContragentsParams('pagination', { page: 1, limit })}
							closeFiltersModal={() => {
								setOpenFilters(false);
							}}
						/>
					</Modal>
				</>
			)}
			{contragents?.length > 0 && !loading ? (
				<Table
					border="1"
					columns={columns}
					onChange={tableSortHandler(setSort)}
					dataSource={contragents}
					rowKey="id"
					locale={{
						triggerDesc: 'Сортировка по возрастанию',
						triggerAsc: 'Нет сортировки',
						cancelSort: 'Сортировка по убыванию',
					}}
					pagination={{
						total: count,
						current: page,
						pageSize: limit,
						showSizeChanger: true,
						pageSizeOptions: ['10', '20', '50'],
						responsive: true,
						onChange: paginationHandler,
					}}
					// scroll={columnsScroll}
					bordered
				/>
			) : (
				<Spin size="large" tip="Загрузка..." />
			)}
			{contragents?.length === 0 && !loading && <h3 className="antd-table-empty">Таблица контрагентов пуста</h3>}
			{!!action && (
				<ContragentsModal
					fetchContragents={fetchContragents}
					checkContragents={(params) => api.getAll('contragents', params)}
					action={action}
					contragentId={contragentId || null}
					closeModal={() => {
						setContragentId(null);
						setIsVisible(false);
						setAction(null);
					}}
					isVisible={isVisible}
				/>
			)}
		</div>
	);
};
