import { Button, Popconfirm } from 'antd';
import { EditTwoTone, DeleteTwoTone } from '@ant-design/icons';

export const ContragentColumns = ({ setContragentId, setAction, setIsVisible, deleteContragent, filterDocuments }) => [
	{
		title: 'Юридическое название',
		dataIndex: 'name',
		sorter: true,
		width: 'calc(33.333% - 40px)',
		render: (_, { id, name }) => (
			<h3>
				<a
					href="#"
					className="table-link"
					onClick={(e) => {
						e.preventDefault();
						setContragentId(id);
						setAction('update');
						setIsVisible(true);
					}}
				>
					{name}
				</a>
			</h3>
		),
		// fixed: 'left',
	},

	{
		title: 'УНП',
		dataIndex: 'unp',
		sorter: true,
		width: 'calc(33.333% - 40px)',
		render: (_, { unp }) => <h3>{unp}</h3>,
	},
	{
		title: 'Номер телефона',
		dataIndex: 'number',
		width: 'calc(33.333% - 40px)',
		render: (_, { number }) => <h3>{number}</h3>,
	},

	{
		title: 'Действия',
		dataIndex: 'actions',
		width: window.innerWidth > 768 ? '135px' : '120px',
		fixed: 'right',
		render: (_, { id, name }) => (
			<div className="icons-list">
				<Button
					className="btn-edit"
					onClick={() => {
						setContragentId(() => id);
						setAction(() => 'update');
						setIsVisible(true);
					}}
				>
					<EditTwoTone twoToneColor="#52c41a" />
				</Button>
				<Popconfirm
					title={`Вы действительно хотите удалить контрагента ${name}? 
					Связанные с ним документы будут удалены.`}
					placement="topRight"
					onConfirm={() => {
						deleteContragent(id);
						filterDocuments(id);
					}}
					okText="Да"
					cancelText="Нет"
				>
					<Button className="btn-delete">
						<DeleteTwoTone twoToneColor="red" />
					</Button>
				</Popconfirm>
			</div>
		),
	},
];
