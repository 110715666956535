const SET_AUTH = 'SET_AUTH';

const initialState = {
	user: null,
};

export const authReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_AUTH:
			return { ...state, ...action.payload };

		default:
			return state;
	}
};

export const setAuthAC = payload => ({ type: SET_AUTH, payload });
