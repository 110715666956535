const ACCESS_TOKEN = 'accessToken';

function parseJWT(token) {
	let parts = token.split('.');

	return {
		header: parsePart(parts[0]),
		payload: parsePart(parts[1]),
		sign: parts[2],
	};
}

function parsePart(str) {
	return JSON.parse(atob(str));
}

class TokenInstance {
	setAccessToken = token => {
		localStorage.setItem(ACCESS_TOKEN, token);
	};
	removeAccessToken = () => {
		localStorage.removeItem(ACCESS_TOKEN);
	};
	getAccessToken = () => {
		return localStorage.getItem(ACCESS_TOKEN) || false;
	};
	getJWTPayload = token => {
		return parseJWT(token).payload;
	};
	createAuthHeader = () => {
		const accessToken = this.getAccessToken();
		return { authorization: `Bearer ${accessToken}` || 'not-auth' };
	};
}

export const Token = new TokenInstance();
