export const selectFilterOption = (input, { name }) => name.toLowerCase().indexOf(input.toLowerCase()) >= 0;

export const findNameById = (itemList = [], itemId = null) =>
	itemList.length > 0 && itemId && itemList.find(({ id }) => id === itemId).name;

export const tableSortHandler =
	setSort =>
	(pagination, filters, { field, order }) => {
		const transformedOrder = (() => {
			switch (order) {
				case 'ascend':
					return 'ASC';
				case 'descend':
					return 'DESC';
				default:
					return undefined;
			}
		})();
		setSort(() => ({ [field]: transformedOrder }));
	};

export const paginationHandler =
	({ updateParams, page, limit }) =>
	async (paginationPage, paginationLimit) => {
		const paginationParams =
			page !== paginationPage ? { page: paginationPage, limit } : { page, limit: paginationLimit };
		updateParams('pagination', paginationParams);
	};
