import { Menu } from 'antd';
import { NavLink } from 'react-router-dom';

export const HeaderNav = ({ isAuth, isLoading }) => {
	const { Item: NavItem } = Menu;
	return (
		<Menu mode="horizontal">
			{isAuth ? (
				<>
					<NavItem key="documents">
						<NavLink to="/documents">Документы</NavLink>
					</NavItem>
					<NavItem key="contragents">
						<NavLink to="/contragents">Контрагенты</NavLink>
					</NavItem>
				</>
			) : (
				<>
					<NavItem key="registration">
						<NavLink to="/registration">Регистрация</NavLink>
					</NavItem>
					<NavItem key="login">
						<NavLink to="/login">Вход в систему</NavLink>
					</NavItem>
				</>
			)}
		</Menu>
	);
};
