import '@assets/less/styles.less';
import { Layout, Spin } from 'antd';
import { LayoutHeader } from '@components/header/Header';
import { DocumentsTable } from '@components/tables/documents/DocumentsTable';
import { ContragentsTable } from '@components/tables/contragents/ContragentsTable';
import { useEffect, useState } from 'react';
import { Route, Switch, Redirect } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Login } from '@components/auth/Login';
import { Registration } from '@components/auth/Registration';
import { api, auth } from '@services/api';
import { setAuthAC } from '@store/reducers/auth';
import { saveDocumentsAC } from '@store/reducers/documents';
import { ProtectedRoute } from '@components/routes/ProtectedRoute';
import { AuthRoute } from '@components/routes/AuthRoute';
import { Error404 } from '@components/Error404';
import { useHistory } from 'react-router-dom';

export const App = () => {
	const { Content } = Layout;
	const dispatch = useDispatch();

	const history = useHistory();

	const isAuth = useSelector((store) => !!store.auth.user);
	const [isLoading, setLoading] = useState(true);

	useEffect(() => {
		(async () => {
			try {
				const user = await auth.checkUser();

				if (user) {
					dispatch(setAuthAC({ user }));
					history.push('/documents');
				}
			} catch (e) {
				console.log(e);
			}
			setTimeout(() => {
				setLoading(false);
			}, 600);
		})();
	}, []);

	return (
		<Layout id="layout">
			<LayoutHeader isAuth={isAuth} isLoading={isLoading} />
			<Layout id="wrapper">
				<Layout id="main">
					<Content className="site-layout-background">
						{!isLoading ? (
							<Switch>
								<ProtectedRoute path="/documents" isAuth={isAuth} component={DocumentsTable} />
								<ProtectedRoute path="/contragents" isAuth={isAuth} component={ContragentsTable} />
								<AuthRoute path="/registration" isAuth={isAuth} component={Registration} />
								<AuthRoute path="/login" isAuth={isAuth} component={Login} />
								<Route
									exact
									path="/"
									render={() => <Redirect to={isAuth ? '/documents' : '/login'} />}
								/>
								<Route path="*" render={() => <Error404 />} />
							</Switch>
						) : (
							<Spin size="large" tip="Загрузка..." />
						)}
					</Content>
				</Layout>
			</Layout>
		</Layout>
	);
};
