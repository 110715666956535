import { Button, Popconfirm, Select, Tag } from 'antd';
import { EditTwoTone, DeleteTwoTone, LinkOutlined } from '@ant-design/icons';
import { findNameById, selectFilterOption } from '@services/utils';
const { Option } = Select;

export const DocumentColumns = ({
	apiUpdate,
	changeDocument,
	types,
	statuses,
	setDocument_id,
	setAction,
	setIsVisible,
	deleteDocument,
	contragents,
	companies,
}) => [
	{
		title: 'Компания',
		dataIndex: 'companyId',
		render: (_, { companyId }) => <h3>{companyId ? findNameById(companies, companyId) : ''}</h3>,
		// fixed: 'left',
	},

	{
		title: 'Документ',
		dataIndex: 'name',
		defaultSortOrder: null,
		sorter: true,
		render: (_, { id, name }) => (
			<h3>
				<a
					href="#"
					className="table-link"
					onClick={(e) => {
						e.preventDefault();
						setDocument_id(id);
						setAction('update');
						setIsVisible(true);
					}}
				>
					{name}
				</a>
			</h3>
		),
		// fixed: 'left',
	},
	{
		title: 'Дата документа',
		dataIndex: 'date',
		defaultSortOrder: null,
		render: (_, { date }) => date && <h3>{date.split('T')[0]}</h3>,
		sorter: true,
	},
	{
		title: 'Тип',
		width: '15%',
		render: ({ type, id }) => (
			<Select
				onChange={async (updatedType) => {
					await apiUpdate('documents', id, { type: updatedType });
					changeDocument('type', updatedType, id);
				}}
				optionFilterProp="children"
				filterOption={selectFilterOption}
				value={type}
				optionLabelProp="label"
				suffixIcon={<i className="ant-menu-submenu-arrow" />}
			>
				{types.map(({ id, name, color = null }) => (
					<Option key={id} name={name} value={id} label={<Tag color={color}>{name}</Tag>}>
						<Tag color={color}>{name}</Tag>
					</Option>
				))}
			</Select>
		),
	},
	{
		title: 'Контрагент',
		dataIndex: 'contragentId',
		defaultSortOrder: null,
		sorter: true,
		render: (_, { contragentId = null }) => <h3>{contragentId ? findNameById(contragents, contragentId) : ''}</h3>,
	},
	{
		title: 'Статус',
		width: '15%',
		render: ({ status, id }) => (
			<Select
				onChange={async (updatedStatus) => {
					await apiUpdate('documents', id, { status: updatedStatus });
					changeDocument('status', updatedStatus, id);
				}}
				optionFilterProp="children"
				filterOption={selectFilterOption}
				value={status}
				optionLabelProp="label"
				suffixIcon={<i className="ant-menu-submenu-arrow" />}
			>
				{statuses.map(({ id, name, color = null }) => (
					<Option key={id} name={name} value={id} label={<Tag color={color}>{name}</Tag>}>
						<Tag color={color}>{name}</Tag>
					</Option>
				))}
			</Select>
		),
	},
	{
		title: 'Сумма, руб',
		dataIndex: 'total',
		render: (_, { total }) => <h3>{total.trim() ? total : ''}</h3>,
	},
	{
		title: 'Действия',
		dataIndex: 'actions',
		width: '140px',
		fixed: 'right',
		render: (_, { id, name, link }) => (
			<div className="icons-list">
				{!!link && (
					<a href={link} className="ant-btn" style={{ marginRight: 5 }} target="_blank" rel="noreferrer">
						<LinkOutlined />
					</a>
				)}
				<Button
					className="btn-edit"
					onClick={() => {
						setDocument_id(id);
						setAction('update');
						setIsVisible(true);
					}}
				>
					<EditTwoTone twoToneColor="#52c41a" />
				</Button>
				<Popconfirm
					title={`Вы действительно хотите удалить документ ${name}?`}
					placement="topRight"
					onConfirm={() => deleteDocument(id)}
					okText="Да"
					cancelText="Нет"
				>
					<Button className="btn-delete" style={{ marginTop: 5 }}>
						<DeleteTwoTone twoToneColor="red" />
					</Button>
				</Popconfirm>
			</div>
		),
	},
];
